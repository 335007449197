<template>
  <div
    v-if="managedBy"
    class="wrapper"
    :title="$t('customer.officehub.managed_by.tooltip')"
  >
    <div class="left">
      <h4 class="headline">
        {{ $t('customer.officehub.managed_by.managed_by') }}
      </h4>
      <p class="name">{{ managedBy.name }}</p>
    </div>
    <div class="right">
      <Icon name="info" class="icon" :size="18" />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { Icon } from '@officeguru/components-vue3';

defineProps({
  managedBy: {
    type: Object,
    default: null,
  },
});
</script>

<style lang="scss" scoped>
@import '@/style/main.scss';

.wrapper {
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.13);
  padding: 11px 24px;
  gap: 8px;
  cursor: default;
  overflow: hidden;
}

.left {
  display: flex;
  gap: 3px;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  flex-shrink: 0;
  color: $color-grey-400;
}

.headline {
  @include font-caption-normal;
  color: $color-grey-300;
}

.name {
  @include overflow-ellipsis;
  @include font-caption-medium;
  color: white;
}
</style>
