<template>
  <div
    class="list-wrapper"
    data-cy="view-conversations-conversation-list"
  >
    <div class="list-header">
      <h2 class="headline">
        {{ $t("shared.conversations.messages") }}
      </h2>

      <div class="action-buttons">
        <ConversationsFilters
          v-if="filterModels"
          :filter-models="filterModels"
          :use-handling="useHandling"
          @apply="$emit('apply-filters', $event)"
          @clear="$emit('clear-filters')"
        />
        <ButtonV2
          v-if="bulkSending"
          type="secondary"
          class="bulk-sending"
          @click="$emit('bulk-button-pressed')"
        >
          <Icon
            name="pencil-square-bold"
            :size="14"
          />
        </ButtonV2>
      </div>
    </div>
    <div
      class="list-section"
      data-cy="view-conversations-conversation"
    >
      <div
        v-if="Array.isArray(conversations) && conversations.length > 0"
        class="list-section__inner"
      >
        <router-link
          v-for="conversation in conversations"
          :key="conversation.id"
          :data-cy="
            conversation.is_support
              ? 'view-conversations-support-conversation'
              : 'view-conversations-conversation'
          "
          class="item"
          :to="conversationDetailRouteConfig(conversation)"
        >
          <ConversationsListItem
            :conversation="conversation"
            :use-handling="useHandling"
          />
        </router-link>

        <LoadMoreButton
          :disabled="conversationsLoading"
          style="margin: 32px 0"
          :show="hasNextPage"
          :load-more-text="$t('shared.conversations.load_more')"
          @click="$emit('load-more-conversations')"
        />
      </div>

      <ConversationsListEmtpy
        v-else-if="!conversationsLoading"
        @clear-filters="$emit('clear-filters')"
      />

      <div
        v-else
        class="list-section__inner"
      >
        <ConversationsSkeleton />
      </div>
    </div>
  </div>
</template>

<script>
import ogAvatar from '../../../assets/og-avatar.svg';
import ConversationsListItem from './conversations-list-item.vue';
import ConversationsFilters from './conversations-filters.vue';
import LoadMoreButton from '../../../components/load-more-button.vue';
import ConversationsListEmtpy from './conversations-list-empty.vue';
import ConversationsSkeleton from './conversations-skeleton.vue';
import ButtonV2 from '../../../components/ButtonV2.vue';
import Icon from '../../../components/Icon.vue';

export default {
  name: 'ConversationsList',
  components: {
    ButtonV2,
    ConversationsSkeleton,
    ConversationsListEmtpy,
    ConversationsListItem,
    ConversationsFilters,
    LoadMoreButton,
    Icon,
  },
  props: {
    useHandling: {
      type: Boolean,
    },
    bulkSending: {
      type: Boolean,
    },
    conversations: {
      type: Array,
      required: true,
    },
    conversationsListMeta: {
      type: Object,
      required: true,
    },
    filterModels: {
      type: Object,
      required: false,
      default: null,
    },
    conversationsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    resultsLimited: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['apply-filters', 'clear-filters', 'load-more-conversations', 'bulk-button-pressed'],
  data() {
    return {
      ogAvatar,
      page: 1,
    };
  },
  computed: {
    hasNextPage() {
      // eslint-disable-next-line camelcase
      return this.conversationsListMeta?.current_page < this.conversationsListMeta?.last_page;
    },
  },
  watch: {
    conversationsListMeta: {
      handler() {
        // eslint-disable-next-line no-unused-expressions
        Array.isArray(this.conversations) && this.conversations.length > 0
          ? this.goToFirstConversation()
          : this.goToConversations();
      },
    },
  },
  methods: {
    conversationDetailRouteConfig(conversation) {
      return {
        name: 'Conversation',
        params: {
          id: conversation.is_support ? 'support' : conversation.id,
        },
        query: this.$route.query,
      };
    },
    async goToFirstConversation() {
      if (this.$route.params.id) {
        // eslint-disable-next-line max-len
        const existingConversationIndex = this.conversations.findIndex(
          (conversation) => conversation.id === this.$route.params.id
            || (this.$route.params.id === 'support' && conversation.is_support),
        );
        const hasExistingConversation = existingConversationIndex !== -1;

        if (hasExistingConversation) {
          return;
        }

        // Don't give up yet, but try to fetch conversation as it could be a deep linked one
        // that has not been loaded yet.
        // We only want to do that if the results are not limited by the search queries
        // or else we could have a conversation on e.g. 'Rengoring' in a filtered list that
        // should only show "Elektriker".
        if (!this.resultsLimited) {
          try {
            await this.$store.dispatch('conversations/get_single', { id: this.$route.params.id });
          } catch (e) {
            if (e.response.status !== 404) throw e;
          }
          const hasExistingConversationNow = !!this.$store.getters['conversations/single']({
            id: this.$route.params.id,
          })?.id;

          if (hasExistingConversationNow) {
            return;
          }
        }
      }

      let firstConversation;
      if (this.conversations[0].is_support && this.conversations.length > 1) {
        firstConversation = this.conversations[1];
      } else {
        firstConversation = this.conversations[0];
      }

      this.$router.replace(this.conversationDetailRouteConfig(firstConversation));
    },
    goToConversations() {
      return this.$router.replace({
        name: 'Conversations',
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/main";

.item {
  text-decoration: none;
  display: block;
  border-bottom: 1px solid $color-grey-tinted-200;
}

.list-wrapper {
  background: #fcfcff;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.list-section {
  overflow-y: auto;
}

.list-header {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 93px;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid $color-grey-tinted-200;
  background: #fcfcff;
  justify-content: space-between;
}

.headline {
  @include header-2-new-semi-bold;
  color: $color-grey-tinted-900;
  margin-bottom: 0;
}

.bulk-sending {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px !important;
  width: 32px;
  padding: 0 !important;
  color: $color-ui-interactive-grey-dark;
  background: none;
  transition: background-color .2s linear, color .2s linear;

  :deep(span) {
    height: 14px;
    margin-top: -4px;
    margin-left: 1px;
  }
}

.action-buttons {
  display: flex;
  gap: 12px;
}
</style>
