import moment from 'moment-timezone';
import i18n from '@/i18n';

export default {
  namespaced: true,
  state: {
    currency: 'DKK',
    language: 'da',
    locale: 'da-DK',
    ui: {
      version: 1,
      showColleagueCardAgainAt: null,
      hasSeenDirectIntro: false,
      hasSeenDirectIntroLunch: false,
      hasSeenSubAccountIntro: false,
      hasSeenImpersonationIntro: false,
    },
  },
  getters: {
    currency(state) {
      return state.currency;
    },
    language(state) {
      return state.language;
    },
    locale(state) {
      return state.locale;
    },
    ui(state) {
      return state.ui;
    },
  },
  mutations: {
    set_currency(state, currency = state.currency) {
      state.currency = currency;
    },
    set_language(state, language = state.language) {
      state.language = language;
      i18n.global.locale = language;
      document.documentElement.setAttribute('lang', language);
    },
    set_ui(state, settings) {
      let newSettings = {};
      if (settings) {
        newSettings = typeof settings !== 'object' ? JSON.parse(settings) : {};
      }
      state.ui = { ...state.ui, ...newSettings };

      try {
        localStorage.setItem('uiSettings', JSON.stringify(state.ui));
      } catch (e) {
        // ignore errors - stringify can not really fail and setItem will
        // fail if *storage is disabled
      }
    },
    setUiProp(state, { key, value }) {
      if (!key) {
        throw new Error('No key provided');
      }
      if (!Object.prototype.hasOwnProperty.call(state.ui, key)) {
        throw new Error(`Unknown ui key: ${key}`);
      }

      state.ui = { ...state.ui, [key]: value };

      try {
        localStorage.setItem('uiSettings', JSON.stringify(state.ui));
      } catch (e) {
        // ignore errors - stringify can not really fail and setItem will
        // fail if *storage is disabled
      }
    },
  },
  actions: {
    setup({ state, commit, rootGetters }, { isPublic }) {
      const mySettings = isPublic
        ? { ...state }
        : rootGetters['users/me'].settings;

      const lang =
        rootGetters['users/me']?.lang ||
        new URLSearchParams(document.location.search).get('lang') ||
        mySettings.language;

      commit('set_language', lang);
      commit('set_currency', mySettings.currency);
      try {
        commit('set_ui', localStorage.getItem('uiSettings'));
      } catch (e) {
        // *storage seems to be not accessible, that's fine - then we
        // always have default settings
      }

      // set moment local globally and provide danish configuration
      // inlined to not add moment dependency (as we plan to remove moment)
      if (lang === 'da') {
        moment.locale(state.locale, {
          months: 'januar_februar_marts_april_maj_juni_juli_august_september_oktober_november_december'.split(
            '_'
          ),
          monthsShort: 'jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec'.split(
            '_'
          ),
          weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split(
            '_'
          ),
          weekdaysShort: 'søn_man_tir_ons_tor_fre_lør'.split('_'),
          weekdaysMin: 'sø_ma_ti_on_to_fr_lø'.split('_'),
          longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD.MM.YYYY',
            LL: 'D. MMMM YYYY',
            LLL: 'D. MMMM YYYY HH:mm',
            LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
          },
          calendar: {
            sameDay: '[i dag kl.] LT',
            nextDay: '[i morgen kl.] LT',
            nextWeek: 'på dddd [kl.] LT',
            lastDay: '[i går kl.] LT',
            lastWeek: '[i] dddd[s kl.] LT',
            sameElse: 'L',
          },
          relativeTime: {
            future: 'om %s',
            past: '%s siden',
            s: 'få sekunder',
            ss: '%d sekunder',
            m: 'et minut',
            mm: '%d minutter',
            h: 'en time',
            hh: '%d timer',
            d: 'en dag',
            dd: '%d dage',
            M: 'en måned',
            MM: '%d måneder',
            y: 'et år',
            yy: '%d år',
          },
          dayOfMonthOrdinalParse: /\d{1,2}\./,
          ordinal: '%d.',
          week: {
            dow: 1, // Monday is the first day of the week.
            doy: 4, // The week that contains Jan 4th is the first week of the year.
          },
        });
      } else {
        moment.locale('en');
      }
    },
  },
};
